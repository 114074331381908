body
  {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  }

code
  {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

td, th
  {
  border: 1px solid gray;
  }

.passwordModalOverlay
  {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  }

.passwordModalContent
  {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  }

@media print
  {
  .PolicyEditor-table tr td.element_group_name > span
    {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    text-align: center;
    display: block;
    margin: auto;
    height: min-content;
    }

  .PolicyEditor-table
    {
    max-width: 7.5in;
    }

  .toolbar
    {
    display: none;
    }

  /* hide interactive screen UI elements */
  .media-screen
    {
    display: none !important;
    }

  button
    {
    display: none;
    }

  select
    {
    border: 0;
    background: none;
    }

  .collapsible-box-title .material-icons-outlined
    {
    display: none;
    }

  .policy-header-container > div
    {
    border: 0 !important;
    }

  .policy-header-container h3
    {
    text-align: center;
    }

  .media-print.attribute-value
    {
    padding: 1px 4px;
    border-radius: 2px;
    box-sizing: border-box;
    display: inline-block;
    }
  }

@media screen
  {
  .media-print {display: none;}
  }

@keyframes App-logo-spin
  {
  from {transform: rotate(0deg);}
  to   {transform: rotate(360deg);}
  }

*
  {
  box-sizing: border-box;
  }

ul
  {
  padding-left: 2em;
  }

table
  {
  border-collapse: collapse;
  width: 100%;
  }

.App
  {
  display: block;
  margin: 0.0em auto 3.0em auto;
  padding: 0.0em 0.0em 3.0em 0.0em;
  min-width: max-content;
  max-width: max-content;
  font-size: 0.8em;
  }

.grid-wrapper
  {
  display: grid;
  grid-template-columns: 1fr 130px;
  grid-gap: 15px;
  margin: 0 auto;
  padding: 0;
  width: max-content;
  }

.sideNote
  {
  display: block;
  margin: 1.0em 0px 1.0em 0px;
  padding: 0;
  text-align: left;
  }

.App h1, .App h2
  {
  text-align: center;
  }

.App-logo
  {
  height: 40vmin;
  pointer-events: none;
  }

@media (prefers-reduced-motion: no-preference)
  {
  .App-logo {animation: App-logo-spin infinite 20s linear;}
  }

.App-header
  {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  }

.App-link
  {
  color: #61dafb;
  }

/*
===================
Policy List
===================
*/

.policy-list-table
  {
  margin-top: 1px;
  border-collapse: collapse;
  }

.policy-list-table tr
  {
  cursor: pointer;
  }

.policy-list-table tr:hover
  {
  background-color: #eef;
  }

.policy-list-table td
  {
  border-width: .5px 0;
  }

/*
===================
Policy Editor
===================
*/

.spacerrow
  {
  display: flex;
  padding-bottom: 30px;
  }

.spacercol
  {
  border-top: 0px;
  border-bottom: 0px;
  }

/* Policy Editor Loading */

.PolicyEditor.loading > div
  {
  opacity: 0;
  }

.PolicyEditor.loading table
  {
  display: none;
  }

.PolicyEditor.loading .LoadingSpinner
  {
  opacity: 1;
  }

/* Policy Editor Table */

.PolicyEditor-table
  {
  border-collapse: collapse;
  }

.PolicyEditor-table thead td
  {
  border: 0;
  }

.PolicyEditor-table th
  {
  border-width: 5px 2px;
  text-transform: uppercase;
  color: white;
  background-color: black;
  }

.PolicyEditor-table .material-icons-outlined
  {
  user-select: none;
  }

.PolicyEditor-table td
  {
  white-space: nowrap;
  }

td
  {
  padding: 0 10px;
  }

.PolicyEditor-table .element_group_name
  {
  width: min-content;
  height: min-content;
  white-space: unset;
  }

  .PolicyEditor-wrapper input[type="text"], .PolicyEditor-wrapper textarea
  {
  width: 90%;
  }

.org-type-badge
  {
  display: inline-block;
  padding: 0 3px;
  border-radius: 3px;
  border: 0;
  margin-right: 3px;
  background: #eee;
  color: black;
  }

.drop-down .drop-down-item
  {
  padding: 5px 10px;
  cursor: pointer;
  user-select: none;
  border: 2px solid transparent;
  }

.drop-down .drop-down-item:hover
  {
  border: 2px solid #eee;
  }

button .material-icons-outlined
  {
  font-size: 1.3em;
  margin-right: .3em;
  vertical-align: bottom; /* TODO: is this the right way to align things? */
  }

button.small-unlabeled
  {
  font-size: 0.8em;
  padding: 1px;
  }

button.small-unlabeled .material-icons-outlined
  {
  margin: 0;
  }

.toolbar
  {
  font-size: 15px;
  margin-bottom: 1em;
  }

.toolbar button
  {
  padding: 6px;
  }

.close-button
  {
  border: none;
  padding: 8px 16px;
  vertical-align: middle;
  overflow: hidden;
  text-decoration: none;
  color: #f00;
  text-align: center;
  white-space: nowrap;
  float: right;
  }

.TLDListSelector-table tr:hover
  {
  background-color: #eef;
  cursor: pointer;
  }

.noBorder
  {
  border:none !important;
  }

.editable-request-field
  {
  background-color: #5bd684
  }

.uneditable-request-field
  {
  background-color: #FFFF66
  }

.forbidden-request-field
  {
  background-color: #ff5353
  }

.wrapper-pdf-meta
  {
  width: 50px
  }

.wrapper-pdf-meta-label
  {
  padding-left: 15px
  }

.compare-list
  {
  white-space: unset;
  }

/*
===================
Legend Table
===================
*/

.policy-legend
  {
  width: min-content;
  font-size: 11px;
  }

.policy-legend table
  {
  border-collapse:collapse;
  color: #999999;
  width: min-content;
  text-align: left;
  }

.policy-legend caption
  {
  display: table-caption;
  caption-side: top;
  text-align: center;
  }

.policy-legend tr
  {
  text-align: left;
  }

.policy-legend td
  {
  padding: 0.3em 0.3em 0.3em 0.3em;
  border: 0;
  border-top: 1px #d3d3d3 solid;
  text-align: left;
  vertical-align: text-top;
  }

.policy-legend span
  {
  display: block;
  color: #666666;
  font-weight:400;
  text-transform:lowercase;
  }
